<script setup>
import { ref, defineEmits, defineProps, inject } from "vue";
import PolicyQuestion from "@/components/ui/baseComponents/policies/PolicySingleQuestion.vue";
const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  includesAnswer: {
    type: Boolean,
    required: true,
  },
});
const emits = defineEmits(["addItem"]);
const showModal = ref(false);
const toast = inject("toast");

const addNewItem = (question) => {
  emits("addItem", question);
};
const questionText = ref("");

const submit = () => {
  if (questionText.value === "" || questionText.value === null) {
    toast.error("Cannot add empty question.");
    return;
  }
  showModal.value = false;
  emits("addItem", { question: questionText.value });
  questionText.value = "";
};

const sectionOptions = [
  { title: "Choose an option", value: "" },
  { title: "Pre-Appraisal", value: "1" },
  { title: "Appraisal", value: "2" },
];
</script>

<template>
  <div class="flex justify-end">
    <base-button class="flex items-center" @click="showModal = true">
      <span class="material-icons mr-1 cursor-pointer"> add </span>
      Add Question
    </base-button>
  </div>

  <div class="grid grid-cols-3 gap-4">
    <div
      v-for="item in props.items"
      :key="item.index"
      class="border border-3 border-gray-400 bg-white py-1 px-4 rounded-md break-words"
    >
      <div class="flex justify-end" v-if="item.type">
        <p class="bg-teal px-3 py-1 rounded-md text-white text-xs">
          {{ item.type }}
        </p>
      </div>
      <div class="text-md">
        <p class="text-md break-all">
          <span class="font-medium text-md">Question: </span>

          <span class="text-xs">{{ item.question }}</span>
        </p>
        <template v-if="item.answer">
          <hr />

          <p class="break-all">
            <span class="font-medium text-md">Answer: </span>
            <span class="text-xs">{{ item.answer }}</span>
          </p>
        </template>
        <template v-if="item.options?.length">
          <hr />

          <div class="break-all">
            <span class="font-medium text-md">Options: </span>
            <div class="text-xs ml-5">
              <p v-for="iter in item.options" :key="iter.index">
                {{ iter.option }}
              </p>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
  <base-modal v-model:show="showModal" @confirm="submit">
    <template #title>Add A new Induction Item</template>
    <template #content>
      <PolicyQuestion
        v-if="props.includesAnswer"
        :title="'Add Question'"
        :sectionOptions="sectionOptions"
        @close-modal="showModal = false"
        @update="addNewItem"
      />
      <textarea
        name="question"
        v-model="questionText"
        id="question"
        class="w-full border border-gray-500 h-36 p-4"
        placeholder="Question Text"
        maxlength="250"
      ></textarea>
    </template>
    <template #successButton> Submit </template>
  </base-modal>
</template>