<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import PolicyWithItems from "@/components/ui/baseComponents/policies/PolicyWithItems.vue";
import { POSTAPPRAISALPOLICY } from "@/store/modules/MA/appraisals/constants.js";
import Swal from "sweetalert2";
const store = useStore();
const router = useRouter();

const savePolicy = async (data) => {
  await store.dispatch(`Appraisals/${POSTAPPRAISALPOLICY}`, data);
  Swal.fire({
    title: "Done",
    text: "Appraisal Questionnaire Created Successfully",
    icon: "success",
  });
  router.push({ name: "ma-appraisals" });
};
</script>
<template>
  <PolicyWithItems @savePolicy="savePolicy" :accaptQuestions="true" />
</template>