<script setup>
import {
  reactive,
  ref,
  inject,
  defineEmits,
  defineProps,
  computed,
  onMounted,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import PolicyInitialDetails from "@/components/ui/baseComponents/policies/PolicyInitialDetails.vue";
import PolicyItemsOrQuestions from "@/components/ui/baseComponents/policies/PolicyItemsOrQuestions.vue";
import { required, maxLength } from "@vuelidate/validators";

const router = useRouter();
const props = defineProps({
  accaptQuestions: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["savePolicy"]);
const store = useStore();

const toast = inject("toast");

const PolicyInitialDetailsRef = ref(null);

const items = reactive([]);

const addNewItem = (data) => {
  items.push(data);
};

onMounted(async () => {
  await store.dispatch("admin/loadRoles", 1);
});

const getRoles = computed(() => {
  return store.getters["admin/getRoles"];
});

const rules = {
  name: { required, maxLength : maxLength(250) },
  description: { required },
  role: { required },
};
const state = reactive({
  name: "",
  description: "",
  role: null,
});

const inputsDetails = [
  {
    title: "Questionnaire Name",
    name: "name",
    placeholder: "I.e Questionnaire for General Users",
    type: "text",
    required: true,
    tag: "input",
  },
  {
    title: "Description",
    name: "description",
    placeholder: "I.e Questionnaire description",
    tag: "textarea",
    required: true,
  },
  {
    title: "Applied to",
    name: "role",
    placeholder: "I.e Questionnaire applied to",
    tag: "select",
    required: true,
    options: getRoles.value,
  },
];

const saveInduction = async () => {
  await PolicyInitialDetailsRef.value.validator.$validate();
  const formInvalid = await PolicyInitialDetailsRef.value.checkValidations();

  if (formInvalid) {
    toast.show("Invalid Questionnaire details");
    return;
  }
  if (items.length < 1) {
    toast.show("Invalid Questions length");
    return;
  }
  emits("savePolicy", {
    ...PolicyInitialDetailsRef.value.formData,
    role: PolicyInitialDetailsRef.value.formData.role?.code,
    questions: items,
    practice: store.getters["MAgeneralModule/getCurrentPractice"],
  });
};

const reRoute = () => {
  router.back();
};
</script>

<template>
  <div class="w-full h-full flex flex-col p-5">
    <div
      class="flex items-center text-teal mb-5 cursor-pointer w-24"
      @click="reRoute"
    >
      <span class="material-icons-outlined"> arrow_back </span>
      go Back
    </div>
    <div class="flex bg-gray-100 rounded-md p-5 border border-gray-300">
      <PolicyInitialDetails
        :inputRules="rules"
        :inputState="state"
        :inputsDetails="inputsDetails"
        :showSaveButton="false"
        ref="PolicyInitialDetailsRef"
      />
    </div>
    <div
      class="
        border border-gray-400
        shadow-md
        rounded-md
        p-5
        flex-grow
        bg-white bg-opacity-75
        mt-4
        flex flex-col
      "
    >
      <PolicyItemsOrQuestions
        :items="items"
        @addItem="addNewItem"
        :includesAnswer="props.accaptQuestions"
      />
    </div>
    <div class="flex justify-end">
      <base-button class="flex items-center" @click="saveInduction">
        <span class="material-icons mr-1 cursor-pointer"> save </span>
        Save Questionnaire
      </base-button>
    </div>
  </div>
</template>