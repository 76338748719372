<script setup>
import { defineProps, defineEmits, ref, reactive, inject, computed } from "vue";

const emits = defineEmits(["close-modal", "update"]);

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  sectionOptions: {
    type: Array,
    default: () => [
      { title: "Choose an option", value: "" },
      { title: "About the Candidate", value: "1" },
      { title: "Role Specific Questions", value: "2" },
      { title: "Salary & Notice", value: "3" },
      { title: "Questions From the Candidate", value: "4" },
    ],
  },
});
const toast = inject("toast");
const question = ref(""),
  question_type = ref(""),
  section = ref(""),
  options = reactive([]),
  haveOptions = computed(() => {
    return question_type.value && question_type.value !== "descriptive";
  });

const closeModal = () => {
  emits("close-modal");
};

const submitFormToParent = () => {
    const payload = {
      question: question.value,
      type: question_type.value,
      head: Number(section.value),
      options: options,
    };
    if (section.value.length && question_type.value.length && question.value) {
      console.log("options : ", options)
      if (question_type.value === "single-choice" && options.length < 2) {
        toast.show("single choice questions cannot have options less then 2");
        return;
      }
      if (question_type.value === "multi-choice" && options.length < 1) {
        toast.show("multiple choice questions cannot have options less then 1");

        return;
      }
      emits("update", payload);
      emits("close-modal");
      return;
    }
    toast.show(
      "please select a question type, section and input question text"
    );
  },
  addOptions = () => {
    options.push({ option: "" });
  },
  deleteRow = (index, option) => {
    var idx = options.indexOf(option);

    if (idx > -1) {
      options.splice(idx, 1);
    }
  };
</script>

<template>
  <form @submit.prevent="submitFormToParent">
    <div
      class="
        flex
        justify-center
        h-screen
        w-screen
        items-center
        z-20
        absolute
        top-0
        left-0
        -translate-x-1/2
      "
    >
      <div
        class="
          hideScroll
          flex flex-col
          min-w-lg
          max-w-6xl
          mx-auto
          rounded-lg
          border border-lighGrey
          z-20
        "
      >
        <!-- Modal Body -->
        <div class="flex flex-col p-4 bg-white">
          <!-- Title -->
          <h2 class="font-semibold text-teal text-2xl self-center pb-2">
            {{ props.title }}
          </h2>
          <!-- Job Title / Contract Type -->
          <div
            class="flex flex-col items-center sm:flex-row my-1 sm:ml-2 md:ml-10"
          >
            <div class="w-full sm:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="question_type"
                >Question Type</label
              >
              <select
                name="question_type"
                id="question_type"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  sm:w-11/12
                  h-10
                  pl-2
                "
                v-model="question_type"
              >
                <option disabled selected value="">Choose an option</option>
                <option value="descriptive">Descriptive</option>
                <option value="single-choice">Single Choice</option>
                <option value="multi-choice">Multi Choice</option>
              </select>
            </div>
            <div class="w-full sm:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="head"
                >Section</label
              >
              <select
                name="section"
                id="section"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  sm:w-11/12
                  h-10
                  pl-2
                "
                v-model="section"
              >
                <option
                  :disabled="item.value === ''"
                  :selected="item.value === ''"
                  :value="item.value"
                  v-for="item in sectionOptions"
                  :key="item.title"
                >
                  {{ item.title }}
                </option>
              </select>
            </div>
          </div>
          <!-- Department / Reports To -->
          <div
            class="flex flex-col items-center sm:flex-row my-1 sm:ml-2 md:ml-10"
          >
            <div class="w-full pr-10">
              <label class="font-semibold text-grey flex pb-1" for="job_title"
                >Question</label
              >
              <input
                type="text"
                name="question"
                v-model="question"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  h-10
                  pl-2
                "
              />
            </div>
          </div>

          <div
            class="flex items-start my-1 sm:ml-2 md:ml-10"
            v-if="haveOptions"
          >
            <div class="w-1/2">
              <div
                v-for="(option, k) in options"
                :key="k"
                class="flex flex-col sm:flex-row pb-1 w-full"
              >
                <div class="w-full pr-1">
                  <input
                    type="text"
                    name="option"
                    v-model="option.option"
                    class="
                      rounded-lg
                      border border-darkGrey
                      bg-lightGrey
                      w-full
                      h-9
                      pl-2
                    "
                  />
                </div>

                <div class="trashIconContainer cursor-pointer">
                  <span
                    class="material-icons text-3xl"
                    @click="deleteRow(k, option)"
                    style="color: red"
                  >
                    remove_circle_outline
                  </span>
                </div>
              </div>
            </div>
            <div class="flex justify-end w-1/2">
              <button
                type="button"
                class="
                  p-2
                  border
                  rounded-md
                  text-sm
                  bg-orange
                  text-white
                  font-semibold
                "
                @click="addOptions"
              >
                Add Options
              </button>
            </div>
          </div>
        </div>
        <div
          class="
            flex flex-row
            items-center
            justify-end
            p-5
            bg-white
            border-t border-lightGrey
            rounded-bl-lg rounded-br-lg
          "
        >
          <div class="flex justify-center">
            <div
              class="
                px-11
                py-2
                text-white
                font-semibold
                bg-orange
                rounded-full
                cursor-pointer
                my-3
              "
              @click="closeModal"
            >
              Cancel
            </div>
            <base-button type="submit"> Add </base-button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

